import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "./components/configs/ScrollToTop";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { LANGUAGES } from "./utils/constants";
import "./styles.css";

// Optimized import with React.lazy for code splitting
const LazyHeader = React.lazy(() =>
  import("./components/layout/header/header")
);
const LazyFooter = React.lazy(() =>
  import("./components/layout/footer/footer")
);
const LazyTopFooter = React.lazy(() =>
  import("./components/layout/top_footer")
);
const LazyRoutes = React.lazy(() => import("./routes"));

const App = () => {
  const [categoriSelectedAutoComplete, setCategoriSelectedAutoComplete] =
    useState("all-areas");
  const [parametroDaPesquisa, setParametroDaPesquisa] = useState("");
  const [isPesquisaNoStickyAutoComplete, setIsPesquisaNoStickyAutoComplete] =
    useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [alternateUrl, setAlternateUrl] = React.useState("");

  const [alternateUrls, setAlternateUrls] = React.useState([
    { locale: location.pathname.split("/")[1] || "en", url: "/pt/" },
  ]);

  const [configuracao, setConfiguracao] = useState({
    corLayout: "#EAEAEA",
    sectionFooter: true,
    corFooter: "#EAEAEA",
  });

  const isTabletMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const [language, setLanguage] = useState(
    LANGUAGES[location.pathname.split("/")[1]?.toUpperCase()] || LANGUAGES.EN
  );
  const [previousLocation, setPreviousLocation] = React.useState(
    location.pathname
  );
  const [changedLanguage, setChangedLanguage] = React.useState(false);
  const [serviceSearchQuery, setServiceSearchQuery] = useState("");
  const [translations, setTranslations] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [dataGridObject, setDataGridObject] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [objects, setObjects] = useState([]);
  const [categorieSelected, setCategorieSelected] = useState("");
  const [newDesertDefault, setNewDesertDefault] = useState([]);

  const [mainStyle, setMainStyle] = useState({
    background: isTabletMobile ? configuracao.corLayout : "none",
  });

  const [isAccountPage, setIsAccountPage] = useState(
    location.pathname.includes("a-minha-conta") ||
      location.pathname.includes("my-account")
  );

  //console.log(process.env);

  // use effect to detect when we move back to the homepage to reset alternate url
  useEffect(() => {
    if (location.pathname.split("/").length === 3) {
      setAlternateUrl(location.pathname === "/en/" ? "/pt/" : "/en/");
    }
    setIsAccountPage(
      location.pathname.includes("a-minha-conta") ||
        location.pathname.includes("my-account")
    );
  }, [location.pathname]);

  useEffect(() => {
    if (isTabletMobile && isAccountPage) {
      setMainStyle({ backgroundColor: "white" });
    } else {
      setMainStyle({
        background: "linear-gradient(180deg, #fff 5%, #e9e9e9 100%)",
      });
    }
  }, [isTabletMobile, isAccountPage]);

  useEffect(() => {
    if (changedLanguage) {
      setChangedLanguage(false);
      navigate(alternateUrl);
      setLoading(true);
    }
  }, [changedLanguage]);

  useEffect(() => {
    if (
      !location.pathname.includes("search") &&
      !location.pathname.includes("pesquisa") &&
      location.pathname !== previousLocation
    )
      setParametroDaPesquisa("");
    if (location.pathname === "/") {
      navigate(`/${language.locale}/`);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Fetch translations and other data based on language
    const fetchData = async () => {
      setServiceSearchQuery("");
      const url = `${
        process.env.REACT_APP_API_ENDPOINT
      }requests/main_request?language=${
        language.supported ? language.locale : "en"
      }`;

      const username = "api";
      const password = "vH5PGsT3wVjm";
      const basicAuth = "Basic " + btoa(username + ":" + password);

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: basicAuth,
          },
        });

        const {
          menu,
          objects: new_objects,
          translations: new_translations,
        } = response.data?.result || {};

        //console.log(new_objects);
        setTranslations(new_translations || {});
        setObjects(new_objects || []);
        setNewDesertDefault(
          new_objects?.filter((obj) => obj.object_id === "6") || []
        );
        menu.sort((a, b) => {
          if (["2"].includes(a.object_id)) {
            return -1;
          }
          if (["1"].includes(a.object_id)) {
            if (["2"].includes(b.object_id)) {
              return 1;
            }
            return -1;
          }
          if (["4"].includes(a.object_id)) {
            if (["1", "2"].includes(b.object_id)) {
              return 1;
            }
            return -1;
          }

          if (["3"].includes(a.object_id)) {
            if (["1", "2", "4"].includes(b.object_id)) {
              return 1;
            }
            return -1;
          }
          return 1;
        });
        setMenuList(menu || []);

        if (location.search !== "") {
          navigate(location.pathname);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [language, location.search, location.pathname]);

  // Function to clear the search bar
  const clearSearchBar = () => {
    setParametroDaPesquisa("");
  };

  return (
    <div
      className={`flex flex-col`}
      style={{ background: isTabletMobile ? configuracao.corLayout : "none" }}
    >
      {!loading && (
        <React.Suspense fallback={<></>}>
          <LazyHeader
            setPage={setPage}
            setData={setData}
            objects={objects}
            menuList={menuList}
            userData={userData}
            language={language}
            setLanguage={setLanguage}
            translations={translations}
            alternateUrls={alternateUrls}
            setAlternateUrls={setAlternateUrls}
            setDataGridObject={setDataGridObject}
            setChangedLanguage={setChangedLanguage}
            parametroDaPesquisa={parametroDaPesquisa}
            setPreviousLocation={setPreviousLocation}
            setParametroDaPesquisa={setParametroDaPesquisa}
            categoriSelectedAutoComplete={categoriSelectedAutoComplete}
            isPesquisaNoStickyAutoComplete={isPesquisaNoStickyAutoComplete}
            setCategoriSelectedAutoComplete={setCategoriSelectedAutoComplete}
            setIsPesquisaNoStickyAutoComplete={
              setIsPesquisaNoStickyAutoComplete
            }
            categorieSelected={categorieSelected}
            setCategorieSelected={setCategorieSelected}
            clearSearchBar={clearSearchBar}
          />
          <main
            style={mainStyle}
            className={`grid gap-4 flex-1 w-full main-container`}
          >
            {((isTabletMobile && !isAccountPage) || !isTabletMobile) && (
              <ScrollToTop />
            )}
            <LazyRoutes
              data={data}
              page={page}
              setData={setData}
              objects={objects}
              setPage={setPage}
              language={language}
              userData={userData}
              setObjects={setObjects}
              translations={translations}
              configuracao={configuracao}
              alternateUrls={alternateUrls}
              isTabletMobile={isTabletMobile}
              dataGridObject={dataGridObject}
              setAlternateUrl={setAlternateUrl}
              setConfiguracao={setConfiguracao}
              newDesertDefault={newDesertDefault}
              setAlternateUrls={setAlternateUrls}
              previousLocation={previousLocation}
              categorieSelected={categorieSelected}
              setDataGridObject={setDataGridObject}
              serviceSearchQuery={serviceSearchQuery}
              setNewDesertDefault={setNewDesertDefault}
              parametroDaPesquisa={parametroDaPesquisa}
              setCategorieSelected={setCategorieSelected}
              setServiceSearchQuery={setServiceSearchQuery}
              setParametroDaPesquisa={setParametroDaPesquisa}
              categoriSelectedAutoComplete={categoriSelectedAutoComplete}
              isPesquisaNoStickyAutoComplete={isPesquisaNoStickyAutoComplete}
              setCategoriSelectedAutoComplete={setCategoriSelectedAutoComplete}
              setIsPesquisaNoStickyAutoComplete={
                setIsPesquisaNoStickyAutoComplete
              }
            />
            {configuracao.sectionFooter && !isTabletMobile && (
              <LazyTopFooter
                objects={objects}
                language={language}
                translations={translations}
              />
            )}
          </main>
          {((isTabletMobile && !isAccountPage) || !isTabletMobile) && (
            <LazyFooter
              objects={objects}
              language={language}
              translations={translations}
              configuracao={configuracao}
              alternateUrls={alternateUrls}
              isTabletMobile={isTabletMobile}
              setAlternateUrls={setAlternateUrls}
            />
          )}
        </React.Suspense>
      )}
    </div>
  );
};

export default App;
