const LANGUAGE_TYPES = {
  DB: "db",
  WIDGET: "widget",
};

const LANGUAGES = {
  EN: { name: "English", locale: "en", supported: true },
  PT: { name: "Português", locale: "pt", supported: true },
  FR: { name: "Français", locale: "fr", supported: false },
  IT: { name: "Italiano", locale: "it", supported: false },
  ES: { name: "Español", locale: "es", supported: false },
  DE: { name: "Deutsch", locale: "de", supported: false },
};

const APIS = {
  DEV: "apidev",
  PROD: "api",
};

module.exports = Object.freeze({
  API: APIS.DEV,
  LANGUAGES,
  LANGUAGE_TYPES,
  ACCOUNT_SECTIONS: {
    LISTS: "lists",
    PROFILE: "profile",
    NEWSLETTERS: "newsletters",
    NOTIFICATIONS: "notifications",
  },
  IS_DEBUG: true,
  FEATURES: {
    USER_AREA: false,
    SAVE_POST: false,
    NEWSLETTER: false,
    NOTIFICATIONS: false,
    GOOGLE_TRANSLATE: false,
    SEARCH_DROPDOWN_MOBILE: false,
    SEARCH_DROPDOWN_DESKTOP: true,
  },
  SHARE_SOURCES: {
    COPY: "copy",
    EMAIL: "email",
    TWITTER: "twitter",
    FACEBOOK: "facebook",
    LINKEDIN: "linkedin",
    WHATSAPP: "WhatsApp",
  },
  SUPPORTED_LANGUAGES: [LANGUAGES.EN, LANGUAGES.PT],
  GOOGLE_LANGUAGES: [
    LANGUAGES.PT,
    LANGUAGES.ES,
    LANGUAGES.FR,
    LANGUAGES.IT,
    LANGUAGES.DE,
    LANGUAGES.EN,
  ],
  SITEMAP_MAIN_DIR: "generated_files/sitemap",
});
