import React, { useState, useEffect } from "react";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import "./ScrollToTop.css"; // Optional: if you want to add custom styles

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Get current route location

  // Show or hide the button based on scroll position
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top
  const scrollToTop = (smooth = false) => {
    window.scrollTo({
      top: 0,
      behavior: smooth ? "smooth" : "auto", // Smooth scroll when the button is clicked, immediate otherwise
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Scroll to top immediately when the route changes
  useEffect(() => {
    scrollToTop(false); // Pass false to scroll immediately on route change
  }, [location]);

  return (
    <>
      {isVisible && (
        <Button
          type="primary"
          onClick={() => scrollToTop(true)} // Smooth scroll when button is clicked
          style={{
            position: "fixed",
            bottom: "50px",
            right: "30px",
            zIndex: 100000000,
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowUpOutlined />
        </Button>
      )}
    </>
  );
};

export default ScrollToTop;
